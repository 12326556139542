import ApiService from "@/core/services/api.service";

export const GET_ITEMS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("item", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const CREATE_ITEM = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("item", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_ITEM = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`item/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_ADJUSTMENT = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`adjustment/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const ADJUSTMENT_PRODUCT = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`adjustment-product/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_ITEM = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`item/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_STATUS = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`item/${id}/status`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const DELETE_ITEM = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.delete(`item/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const IMPORT_ITEMS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.upload("item/import", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
